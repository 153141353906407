import React from 'react';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import callToActionHook from 'hooks/call-to-action/call-to-action.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './CallToAction.interface';

import { callToAction, callToAction__heading, callToAction__content, callToAction__button } from './CallToAction.scss';

export default ({ slug }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, callToActionHook());

  return (
    <div className={callToAction}>
      <Markdown className={callToAction__heading} source={node.heading} container />
      <Markdown className={callToAction__content} source={node.content} container />

      <Button role="primary" className={callToAction__button} to={node.button_link}>
        {node.button_text}
      </Button>
    </div>
  );
};
