import React from 'react';
import Img from 'gatsby-image';
import slugify from 'react-slugify';

import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import faqBlockHook from 'hooks/faq-block/faq-block.hook';

import { grid, block, block__link, block__image, block__body, block__body__title } from './FaqBlock.scss';

export default (): JSX.Element => {
  const blocks = faqBlockHook();

  return (
    <div className={grid}>
      {blocks.map(({ node }) => (
        <div className={block} key={`faq-block-${slugify(node.title)}`}>
          <Link to={node.link} className={block__link}>
            <div className={block__image}>
              <Img fluid={node.image.localFile.childImageSharp.fluid} alt={node.image_alt} style={{ width: '100%' }} />
            </div>

            <div className={block__body}>
              <h3 className={block__body__title}>
                <Markdown source={node.title} />
              </h3>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};
